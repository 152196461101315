import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, lastValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppService } from './app.service';
import { DataCheckService } from './shared/services/data-check.service';
import { DeviceInfoService } from './shared/services/device-info.service';
@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  isSuperUser: boolean;

  constructor(
    private appService: AppService,
    private router: Router,
    private dataCheckService: DataCheckService,
    private deviceInfoService: DeviceInfoService
  ) {
    this.isSuperUser = this.dataCheckService.isSuperUser();
  }
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // convert promise to observable using 'from' operator
    return from(this.handle(req, next)).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            // the token is invalid; clear the localstorage so that 'LoadGuard' wont redirect back into the app.
            const user = this.appService.getUserData();
            localStorage.clear();
            if (user?.token_expiry) {
              localStorage.setItem('email', user?.profile?.email);
            }
            this.router.navigate(['/login']);
          }
          if (error.status === 404) {
            this.router.navigate(['/404']);
          }
          if (error.status === 503) {
            this.router.navigate(['/503']);
          }
          if (error.status === 403) {
            if (this.isSuperUser === true) {
              this.router.navigate(['gtadmin/dashboard']);
            } else {
              this.router.navigate(['/dashboard']);
            }
          }
          // Payment Failed Calls
          if (error.status === 402) {
            if (this.isSuperUser) {
              this.router.navigate(['/gtadmin/dashboard']);
            } else if (this.dataCheckService?.isSubscriberAdmin()) {
              this.router.navigate([
                '/subscription',
                {
                  state: error.error.message,
                },
              ]);
            } else {
              this.router.navigate(['/dashboard']).then(() => {
                window.location.reload();
              });
            }
          }
        }
        return throwError(() => error);
      })
    );
  }
  async handle(req: HttpRequest<any>, next: HttpHandler) {
    req = this.addToken(req, this.appService.getToken());
    this.deviceInfoService.updateGpsCache();
    return await lastValueFrom(next.handle(req));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    let headerDict: any = {
      Authorization: `Bearer ${token}`,
      timeoffset: String(new Date().getTimezoneOffset()),
    };

    try {
      const diffSeconds: any = 30; // 30 seconds
      const JsonGpsRecord = JSON.parse(
        sessionStorage.getItem('gpsRecord') || '{}'
      );
      const lastSavedGpsRecord = JSON.parse(
        sessionStorage.getItem('lastSavedGpsRecord') || '{}'
      );
      // passing gps in all the api calls to track user. if the date time is same as the previous due to simultaneous api call, discard that. and also when the gps has not changed
      if (
        JsonGpsRecord?.recorded_at &&
        (!lastSavedGpsRecord?.recorded_at ||
          (lastSavedGpsRecord?.recorded_at &&
            JsonGpsRecord?.recorded_at >=
              lastSavedGpsRecord?.recorded_at + diffSeconds &&
            (lastSavedGpsRecord?.gps?.lat !== JsonGpsRecord?.gps?.lat ||
              lastSavedGpsRecord?.gps?.lon !== JsonGpsRecord?.gps?.lon)))
      ) {
        sessionStorage.setItem(
          'lastSavedGpsRecord',
          JSON.stringify(JsonGpsRecord)
        );
        headerDict['gpsRecord'] = sessionStorage.getItem('gpsRecord');
      }
    } catch (error) {}

    return request.clone({
      setHeaders: headerDict,
    });
  }
}
